@font-face {
  font-family: "Firacode";
  src: url(./fonts/firacode.ttf);
}
/* WRAPPER*/
.app-container {
  height: calc(100vh - 16px);
  margin: 0 auto;
  background-color: black;
  border: 8px solid #b9b9b9;
  position: relative;
}
.app-wrapper {
  height: calc(100% - 40px);
  border: 1px solid #fff;
  font-size: 14px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-wrapper_col {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  margin: 15px 15px 0;
}
.app-wrapper_item {
  width: 120px;
  margin: 5px 0;
  padding: 15px 0;
  border: 1px solid transparent;
}
.app-wrapper_item:hover {
  background-color: rgba(189, 148, 240, 0.4);
  border: 1px dotted #be94f0;
}
.app-wrapper_item > button {
  justify-content: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
.app-wrapper_item__name {
  background-color: #fff;
  font-size: 11px;
  border-radius: 5%;
  color: #000;
  width: 100px;
  padding: 10px;
  min-height: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.app-wrapper_item__icon {
  margin: 0 0 5px;
}
.app-wrapper_item__icon > img {
  width: 50px;
  height: 50px;
}
.app-wrapper_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.app-wrapper_center > img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}
.app-wrapper_greetings {
  text-align: center;
  font-size: 18px;
}
.app-wrapper_greetings__title {
  text-align: center;
  font-size: 30px;
}
.app-wrapper_greetings__mail {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-top: 10px;
}
.app-wrapper_greetings__mail > img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
/*END WRAPPER*/

/*FOOTER*/
.app-footer > footer {
  height: 40px;
  width: 100%;
  background-color: #b9b9b9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-footer_col {
  height: 100%;
  margin-top: 5px;
  display: flex;
  position: relative;
}
.app-footer_col > button {
  background-color: #d3d3d3;
  width: 100px;
  height: 100%;
  font-size: 12px;
  font-family: "Dogica";
  cursor: pointer;
  height: 100%;
  border: 1px solid #b7b7b7;
  box-shadow: 1px 1px #000;
}
.app-footer_contact {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.app-footer_contact > a > img {
  width: 25px;
  height: 25px;
}
.app-footer_contact > a {
  border: 1px solid transparent;
  margin: 0 10px;
  cursor: pointer;
}
.app-footer_contact > a:hover {
  border-color: #d3d3d3;
}
.app-footer_col > button:hover {
  background-color: #b9b9b9;
}
.app-footer_date {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app-footer_start {
  display: flex;
  align-items: center;
  position: absolute;
  background-color: #b9b9b9;
  padding: 10px;
  top: -53px;
  font-size: 10px;
  cursor: pointer;
}
.app-footer_start:hover {
  opacity: 0.7;
}
.app-footer_start > img {
  width: 30px;
  margin-right: 5px;
}
.app-settings {
  position: absolute;
  top: 25vh;
  left: 25vw;
  background-color: #fff;
  border: 1px solid #b9b9b9;
  width: 350px;
  height: 300px;
  font-family: "Firacode";
}
.app-settings_wrapper {
  display: flex;
  height: calc(100% - 15px);
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}
.app-settings_header {
  background-color: #b9b9b9;
  height: 35px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.app-settings_header > button:hover {
  opacity: 0.7;
}
.app-settings_header > button {
  margin: 0 10px;
  background-color: red;
  color: #fff;
  cursor: pointer;
  font-size: 8px;
  border: 1px solid #ccc;
  padding: 5px;
}
.app-settings_language {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 10px;
}
.app-settings_language__label {
  display: flex;
  font-weight: bold;
}
.app-settings_language__options {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 15px 0 0;
}
.app-settings_language__option {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.app-settings_language button {
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 14px;
  font-family: "Firacode";
  cursor: pointer;
  min-width: 100px;
  text-align: left;
}
.app-settings_language button:hover {
  border: 1px dotted #b9b9b9;
}

.app-settings_language__icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}
/* END FOOTER*/

/* APPS MODAL */
.app-modal {
  background-color: white;
  color: #000;
  font-family: "Firacode";
  display: flex;
  flex-direction: column;
  min-height: 450px;
}
.app-modal_header {
  display: flex;
  background-color: #b9b9b9;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
}
.app-modal_header__close {
  left: 5px;
  position: absolute;
}
.app-modal_header__close > button {
  margin-left: 5px;
  background-color: red;
  color: #fff;
  cursor: pointer;
  font-size: 8px;
  padding: 5px;
  border: 1px solid #ccc;
}
.app-modal_header__close > button:hover {
  opacity: 0.7;
}
.app-modal_content__info {
  font-size: 10px;
  color: #b9b9b9;
}
.app-modal_content {
  padding: 15px;
  height: 100%;
}
.app-modal_content__posts {
  display: flex;
  height: 100%;
}
.app-modal_content__list___title {
  font-weight: bold;
  margin-bottom: 10px;
}
.app-modal_content__list {
  width: 20%;
  padding-right: 10px;
}
.app-modal_content__list > span:hover {
  color: #203354;
  cursor: pointer;
}
.app-modal_content__post {
  width: 80%;
  border-left: 1px solid #c6c6c6;
  padding: 0 20px;
  text-align: justify;
}
.app-modal_content__career {
  display: flex;
  justify-content: space-between;
}
.app-modal_content__career___experience {
  width: 33%;
  padding: 0 20px;
  border-right: 1px solid #ccc;
}
.app-modal_content__career___experience:last-child {
  border: none;
}
.app-modal_content__career__logo {
  display: flex;
  justify-content: center;
  margin: 10px 0 20px;
}
.app-modal_content__career__logo > img {
  width: 100px;
  height: 100px;
}
.app-modal_content__career__company {
  font-size: 14px;
}
.app-modal_content__career__text {
  margin-top: 15px;
  text-align: justify;
  font-size: 12px;
}
.app-modal_content__stack__logo > img {
  width: 100px;
  height: 100px;
}
.app-modal_content__stacks {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.app-modal_content__stack {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 45%;
}
.app-modal_content__stack___footer {
  background-color: #b9b9b9;
  height: 10%;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}
.app-modal_content__stack___footer > a:hover {
  opacity: 0.5;
}
.app-modal_about__text {
  margin: 10px 0;
  font-size: 12px;
}
.app-modal_about {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.app-modal_about__footer {
  font-size: 10px;
  color: #b9b9b9;
}
/*END APPS MODAL*/
.app-mobile-wrapper {
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}
.app-mobile-wrapper > img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.app-mobile-title,
.app-mobile-text {
  color: #fff;
  text-align: center;
}
.app-mobile-title {
  font-size: 24px;
  background-color: black;
  margin-bottom: 30px;
}
.app-mobile-text {
  font-size: 10px;
  max-width: 70%;
  margin-top: 0px;
}
.app-mobile-icons { 
  display: flex;
  margin-top: 30px;
}
.app-mobile-icons img{ 
  width: 30px;
  height: 30px;
  margin: 0 10px;
}
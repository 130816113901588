@font-face {
  font-family: "Dogica";
  src: url(./fonts/dogica.otf);
}
body {
  margin: 0;
  font-family: "Dogica";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(./assets/background.jpg);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  font-family: "Dogica";
}

@media (min-width: 768px) {
  body {
    background-repeat: round;
    max-width: 1366px;
    margin: 0 auto;
  }
}
